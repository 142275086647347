import { useContext, useState } from 'react'
import { appContext } from '../../context'
import Badge from '../Badge'
import Button from '../Button'
import { ChevronDown, ChevronUp } from '../icons'
import DiscountForm from '../DiscountForm'
import cartSummaryStyles from './CartSummary.module.scss'
import styles from './Total.module.scss'
import useRemoveDiscountCode from '../../hooks/useRemoveDiscountCode'

interface IComponentProps {
  isDiscounted?: boolean;
  showDiscountCodeForm?: boolean;
  discountedPrice?: number | null;
  discountTitle?: string[] | null;
  totalPrice: number | null;
  totalLabelText?: string | null;
}

const Total = ({
  isDiscounted = false,
  showDiscountCodeForm = true,
  discountedPrice,
  discountTitle,
  totalPrice,
}: IComponentProps) => {
  const context = useContext(appContext)
  const [isDiscountFormActive, setIsDiscountFormActive] = useState(false)
  const toggleDiscountForm = () => {
    setIsDiscountFormActive(!isDiscountFormActive)
  }
  let discountCodeToRemove
  const {
    removeDiscountCode,
  } = useRemoveDiscountCode({
    'discountCode': discountCodeToRemove,
  })

  const removeDiscountCodeHandler = (discountCode: GenericObject) => {
    discountCodeToRemove = discountCode
    removeDiscountCode()
  }

  const totalPriceView = `${context?.currencySymbol}${totalPrice?.toFixed(2)}`
  const discountedPriceView = discountedPrice && `${context?.currencySymbol}${discountedPrice?.toFixed(2)}`

  const strikethroughPriceClass = (isDiscounted && discountedPrice) ? styles.strikethroughPrice : ''

  //don't show discount box if there's a product level disocunt
  if (discountedPrice && discountTitle?.length === 0) {
    showDiscountCodeForm = false
  }

  return (
    <div className={cartSummaryStyles.sectionWrapper}>
      <h3 className={cartSummaryStyles.boxHeading}>Total</h3>

      {(totalPrice === null || typeof totalPrice === 'undefined') && 'Price to be determined'}
      {typeof totalPrice === 'number' &&
        <div className={styles.priceRow}>
          {isDiscounted && <div className={styles.price} data-testid='cart-discounted-price-total'>{discountedPriceView}</div>}
          <div
            data-testid="total-price"
            className={`${styles.price} ${strikethroughPriceClass}`}
          >
            {totalPriceView}
          </div>
        </div>
      }

      {(discountTitle && discountTitle.length > 0) && 
        <h5>Promos applied</h5> 
      }
      <div className={styles.priceRow} data-testid="discount-price-row">
        <div className={styles.priceLabel}>
          {
            discountTitle?.map((codes) => (
              showDiscountCodeForm ?
                <Badge bgColor='#085482' color='#fff' key={codes}>
                  <span className={styles.discountBadgeDeletable}>{codes}
                    <button className={styles.removeVoucher}
                      onClick={() => removeDiscountCodeHandler({ codes })}
                      onKeyDown={() => removeDiscountCodeHandler({ codes })}></button>
                  </span>
                </Badge>
                :
                <Badge bgColor='#085482' color='#fff' key={codes}>
                  <span className={styles.discountBadge}>{codes}</span>
                </Badge>
            ))
          }
        </div>
      </div>

      {showDiscountCodeForm &&
        <div className={styles.discountSection}>
          <Button
            styleType='text'
            onClick={toggleDiscountForm}
            testId='discount-form-trigger'
          >
            <span className={styles.discountBtnText}>Apply discount</span>
            {!isDiscountFormActive && <ChevronDown fill="#3E7D34" stroke="#3E7D34" className={styles.icon} width={28} height={28} strokeWidth={8} />}
            {isDiscountFormActive && <ChevronUp fill="#3E7D34" stroke="#3E7D34" className={styles.icon} width={28} height={28} strokeWidth={8} />}
          </Button>

          {isDiscountFormActive && <div data-testid="discount-form-content">
            <DiscountForm />
          </div>
          }

        </div>
      }
    </div>
  )
}

export default Total